var loginForm = $("#loginForm");
loginForm.submit(function(e){

    e.preventDefault();

    var formData = loginForm.serialize();
    $.ajax({
        url:'/auth/login',
        type:'POST',
        data:formData,
        success:function(){
            window.location  ='/cabinet/profile';
        },
        error: function (data) {
            /*$('#login-error').html('<span>'+data.responseJSON+'</span>');
            if(data.responseJSON.password!==undefined){
                $('#login-error').html('<span>'+data.responseJSON.password[0]+'</span>');
            }
            if(data.responseJSON.email!==undefined){
                $('#login-error').append('<span>'+data.responseJSON.email[0]+'</span>');
            }*/
            $.each(data.responseJSON, function (index, value) {
                $('#loginForm .error_box').empty();
                $('<span>' + value + '</span>').appendTo($('#loginForm').find('.error_box[data-attr="' + index + '"]'));
            });
        }
    });
});