var registerForm = $("#registerForm");
registerForm.submit(function(e){
    e.preventDefault();
    var formData = registerForm.serialize();
    $.ajax({
        url:'/auth/register',
        type:'POST',
        data:formData,
        success:function(){
            var popup = new Foundation.Reveal($('#add_to_cart__modal').addClass('medium add_to_cart__modal').html('<button class="close_btn" data-close aria-label="Close reveal" type="button"></button><h3 class="modal__title registration_complete">Ура! Остался всего 1 шаг для завершения регистрации! Чтобы подтвердить аккаунт, перейдите, пожалуйста, по ссылке, отправленной на вашу почту.</h3><div class="modal__img_wrapper registration_complete"><img class="modal__img registration_complete" width="120px" src="/img/ok_big.png"></div><div class="modal__registration_complete_btn row"><div class="columns"><a href="javascript:void(0);" class="modal_btn button registration_complete_btn" data-close aria-label="Close reveal">Ок</a></div></div>'));
            popup.open();

            //window.location  ='/';
        },
        error: function (data) {
            //console.log(data.responseJSON);
            $.each(data.responseJSON, function (index, value) {
                $('#registerForm .error_box').empty();
                $('<span>' + value + '</span>').appendTo($('#registerForm').find('.error_box[data-attr="' + index + '"]'));
            });
        }
    });
});